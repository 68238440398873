import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SubpageHero from "../components/subpage-hero"


const Partner = () => (
  <Layout>
    <SEO title="Get Involved" />

    <SubpageHero
       title="Get Involved"
    />

    <div className="post-content">
      <h2>Individual Memberships</h2>
      <p>Test content - Praesent nec nisl a purus blandit viverra. Vestibulum purus quam, scelerisque ut, mollis sed, nonummy id, metus. Maecenas ullamcorper, dui et placerat feugiat, eros pede varius nisi, condimentum viverra felis nunc et lorem. Etiam iaculis nunc ac metus. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero.</p>
    </div>

  </Layout>
)

export default Partner